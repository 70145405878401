.App {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    max-height: 100vh;
    overflow: hidden;
    background: #F5F6F7;
}


.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #ff913a;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 40%;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.container {
    height: 100vh;
    overflow: hidden;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 45px 45px 25px;
}


.logo {
    width: 200px;
    height: auto;
}

.search {
    background: none;
    padding: 10px;
    border: none;
    border-bottom: 1px solid #FF9900;
    color: white;
    width: 90%;
    transition: border 0.25s ease-in-out;


}

.search:focus {
    transition: border 0.25s ease-in-out;
    outline: none;
    border-bottom: 1px solid #FFF;

}

.title {
    padding: 0;
    margin-top: 10px;
    font-weight: 300;
    font-size: 23px;
    text-transform: uppercase;
    color: #FF9900;
    font-family: 'Montserrat', sans-serif;
}

.wrapper {
    height: 100%;
    display: grid;
    grid-template-columns: 350px auto;
}

.radar {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;

}

.sidebar {
    height: 100%;
    width: 350px;
    background: black;
    grid-column: 0;

}

.info-list {
    color: white;
    height: calc(100vh - 200px);
    text-align: left;
    padding: 0 35px 35px;
    overflow-y: scroll;

}

.quadrant-name {
    text-transform: capitalize;
    font-size: 18px;
    text-align: center;
    padding: 20px;
    position: sticky;
    top: 0;
    border-collapse: separate;
    background: black;
    z-index: 10;
}

.quadrant-name:after {
    position: absolute;
    left: 0;
    width: 100%;
    content: '';
    bottom: 1px;
    border-bottom: 1px solid #FF9900;
}


.ring-name {
    text-transform: capitalize;
    font-size: 14px;
    line-height: 14px;
    padding: 10px 0;
}

.item {
    overflow: hidden;
    transition: margin 0.25s ease-in-out;
    margin-bottom: 0;
    margin-left: -10px;
    padding-left: 10px;
}


.item-button {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
}

.item-icon {
    transform: rotateZ(0deg);
    transition: 0.15s ease-in-out;
}

.item-icon__active {
    transform: rotateZ(180deg);
    transition: 0.15s ease-in-out;
}


.item-main {
    display: flex;
    justify-content: center;
    align-items: center;
}

.item-id {
    border-radius: 14px;
    width: 20px;
    height: 20px;
    color: black;
    background: white;
    font-weight: 700;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.item-name {
    text-transform: capitalize;
    font-size: 14px;
}

.item-description {
    padding: 0 0 15px 0;
    font-size: 14px;
}

.anim-item-enter {
    max-height: 0;
    padding: 0;
    opacity: 0;
}

.anim-item-enter-active {
    max-height: 150px;
    padding: 0 0 15px 0;
    opacity: 1;
    transition: all 0.25s ease-in-out;
}

.anim-item-exit {
    padding: 0 0 15px 0;
    opacity: 1;
    max-height: 150px;
}

.anim-item-exit-active {
    max-height: 0;
    padding: 0;
    opacity: 0;
    transition: all 0.25s ease-in-out;
}

.info-footer {
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    padding: 8px 0 8px 0;
    cursor: pointer;
}

.info-footer:hover svg {
    color: #115efb;
    cursor: pointer;

}

.uman-logo-icon {
    height: 12px;
    width: auto;
    margin-left: 6px;
    margin-bottom: 2px;
    color: white !important;
}

.url-icon {
    height: 18px;
    width: 18px;
}

.info-url-name {
    height: 12px;
    width: auto;
    margin: 0 0 0 6px;
    color: white !important;
}

.info-url-name a:hover, a:visited, a:link, a:active {
    color: #fff;
    text-decoration: none;
}


::-webkit-scrollbar {
    width: 8px;
    height: 0;
}


::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 14px 14px white;
    border: solid 3px transparent;
    border-radius: 14px;
}

::-webkit-scrollbar-button {
    display: none;
}
